interface StreamMessage<T> {
  channel?: string
  payload?: T
}

export function useStreaming<T>(channel: Ref<string | undefined>) {
  const { $ws } = useNuxtApp()
  let unsubscribe: (() => void) | undefined

  watch(channel, (channel) => {
    if ($ws && channel) {
      $ws.send(JSON.stringify({ type: 'subscribe', channel }))
      unsubscribe = () => $ws.send(JSON.stringify({ type: 'complete', channel }))
    }
  }, { immediate: true })

  onBeforeUnmount(() => {
    if (unsubscribe) {
      unsubscribe()
      unsubscribe = undefined
    }
  })

  return {
    stream: computed<T | undefined>(() => {
      if (import.meta.client && channel.value) {
        try {
          const payload = $ws.data.value
          const message: StreamMessage<T> = payload ? JSON.parse(payload) : {}
          if (message.channel === channel.value) {
            return message.payload
          }
        } catch (err) {
          console.error(err)
        }
      }
    })
  }
}
