interface StreamMessage<T> {
  type?: string
  payload?: T
}

export async function usePaginatorFetch<T>(
  url: MaybeRefOrGetter<string>,
  keyProp: keyof T,
  stream: Ref<StreamMessage<T> | undefined> = ref<StreamMessage<T>>(),
  onFn?: (eventType: 'create' | 'update' | 'delete', item: T) => void,
) {
  const maxId = ref<NonNullable<T>[keyof T] | undefined>()

  const { data: items, ...rest } = await useFetch<T[]>(url, { params: { max_id: maxId } })

  watch(maxId, async (current, last) => {
    if (current !== last) {
      const value = await $fetch<T[]>(toValue(url), { params: { max_id: current } })
      if (Array.isArray(value) && value.length) {
        const records = items.value ?? []
        for (const item of value) {
          const index = records.findIndex(record => record[keyProp] === item?.[keyProp])
          if (index === -1) {
            records.push(item)
          }
        }
      }
    }
  })

  function loadNext() {
    maxId.value = items.value?.slice().pop()?.[keyProp]
  }

  function getEventType(type?: string) {
    switch (type) {
      case 'create':
        return 'create'
      case 'update':
        return 'update'
      case 'delete':
        return 'delete'
    }
  }

  watch(stream, (message) => {
    const { type, payload } = message ?? {}
    const eventType = getEventType(type)
    if (eventType && payload) {
      if (onFn) {
        onFn(eventType, payload)
      }
      const records = items.value ?? []
      const index = records.findIndex(record => record[keyProp] === payload?.[keyProp])
      if (eventType === 'create' && payload && index === -1) {
        records.unshift(payload)
      }
      if (records) {
        if (eventType === 'update' && payload) {
          if (index >= 0) {
            records[index] = payload
          }
          else {
            records.unshift(payload)
          }
        }
        if (eventType === 'delete') {
          if (index >= 0) {
            records.splice(index, 1)
          }
        }
      }
    }
  })

  return {
    items: computed(() => items.value ?? []),
    loadNext,
    ...rest,
  }
}

export function useEndAnchor(loadNext: () => void) {
  const endAnchor = ref<HTMLDivElement>()
  if (import.meta.client) {
    const bound = useElementBounding(endAnchor)
    const isInScreen = computed(() => {
      return bound.top.value < window.innerHeight * 2
    })
    const deactivated = useDeactivated()
    useIntervalFn(() => {
      bound.update()
    }, 1000)

    watch(
      isInScreen,
      () => {
        if (
          isInScreen.value
          // No new content is loaded when the keepAlive page enters the background
          && deactivated.value === false
        ) {
          loadNext()
        }
      },
    )
  }
  return endAnchor
}
